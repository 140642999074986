<template>
  <div id="maintainRecord">
    <el-dialog
      :title="maintainRecordFormTitle"
      width="680px"
      :visible.sync="maintainRecordDialogVisible"
      :close-on-click-modal="false"
      @close="maintainRecordDialogClose"
    >
      <el-form
        ref="maintainRecordFormRef"
        :model="maintainRecordForm"
        :rules="maintainRecordFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="deviceId">
              <el-select
                v-model="maintainRecordForm.deviceId"
                placeholder="请选择设备"
                clearable
                @change="deviceChange"
              >
                <el-option
                  v-for="item in deviceList"
                  :key="item.id"
                  :label="item.deviceName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备编号" prop="deviceNo">
              <el-input v-model="maintainRecordForm.deviceNo" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号" prop="spec">
              <el-input v-model="maintainRecordForm.spec" readonly />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="维修/维护原因" prop="repairCause">
              <el-input
                v-model="maintainRecordForm.repairCause"
                placeholder="请输入维修/维护原因"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维修/维护结果" prop="repairResult">
              <el-input
                v-model="maintainRecordForm.repairResult"
                placeholder="请输入维修/维护结果"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维修/维护人" prop="repairMan">
              <el-input v-model="maintainRecordForm.repairMan" placeholder="请输入维修/维护人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认人" prop="confirmor">
              <el-input v-model="maintainRecordForm.confirmor" placeholder="请输入确认人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维修/维护日期" prop="repairDate">
              <el-date-picker v-model="maintainRecordForm.repairDate" placeholder="请选择维修/维护日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认日期" prop="verifyTime">
              <el-date-picker v-model="maintainRecordForm.verifyTime" placeholder="请选择确认日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="maintainRecordForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="maintainRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="maintainRecordFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备名称">
        <el-input v-model="searchForm.deviceName" placeholder="请输入设备名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="maintainRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column label="维修/维护日期">
        <template slot-scope="scope">
          <span v-if="scope.row.repairDate">{{ scope.row.repairDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="repairMan" label="维修/维护人" />
      <el-table-column prop="repairCause" label="维修/维护原因" />
      <el-table-column prop="repairResult" label="维修/维护结果" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="maintainRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMaintainRecord, deleteMaintainRecord, updateMaintainRecord, selectMaintainRecordInfo, selectMaintainRecordList } from '@/api/device/maintainRecord'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      maintainRecordDialogVisible: false,
      maintainRecordFormTitle: '',
      maintainRecordForm: {
        id: '',
        deviceId: '',
        deviceName: '',
        deviceNo: '',
        spec: '',
        repairDate: '',
        repairCause: '',
        repairResult: '',
        repairMan: '',
        confirmor: '',
        verifyTime: '',
        remarks: ''
      },
      maintainRecordFormRules: {
        deviceId: [{ required: true, message: '设备不能为空', trigger: ['blur', 'change']}]
      },
      maintainRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceName: ''
      },
      deviceList: []
    }
  },
  created () {
    selectBaseInfoList().then(res => {
      this.deviceList = res.list
    })
    selectMaintainRecordList(this.searchForm).then(res => {
      this.maintainRecordPage = res
    })
  },
  methods: {
    maintainRecordDialogClose () {
      this.$refs.maintainRecordFormRef.resetFields()
    },
    maintainRecordFormSubmit () {
      if (this.maintainRecordFormTitle === '维修保养记录详情') {
        this.maintainRecordDialogVisible = false
        return
      }
      this.$refs.maintainRecordFormRef.validate(async valid => {
        if (valid) {
          if (this.maintainRecordFormTitle === '新增维修保养记录') {
            await addMaintainRecord(this.maintainRecordForm)
          } else if (this.maintainRecordFormTitle === '修改维修保养记录') {
            await updateMaintainRecord(this.maintainRecordForm)
          }
          this.maintainRecordPage = await selectMaintainRecordList(this.searchForm)
          this.maintainRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.maintainRecordFormTitle = '新增维修保养记录'
      this.maintainRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaintainRecord(row.id)
        if (this.maintainRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.maintainRecordPage = await selectMaintainRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.maintainRecordFormTitle = '修改维修保养记录'
      this.maintainRecordDialogVisible = true
      this.selectMaintainRecordInfoById(row.id)
    },
    handleInfo (index, row) {
      this.maintainRecordFormTitle = '维修保养记录详情'
      this.maintainRecordDialogVisible = true
      this.selectMaintainRecordInfoById(row.id)
    },
    selectMaintainRecordInfoById (id) {
      selectMaintainRecordInfo(id).then(res => {
        this.maintainRecordForm.id = res.id
        this.maintainRecordForm.deviceId = res.deviceId
        this.maintainRecordForm.deviceName = res.deviceName
        this.maintainRecordForm.deviceNo = res.deviceNo
        this.maintainRecordForm.spec = res.spec
        this.maintainRecordForm.repairDate = res.repairDate
        this.maintainRecordForm.repairCause = res.repairCause
        this.maintainRecordForm.repairResult = res.repairResult
        this.maintainRecordForm.repairMan = res.repairMan
        this.maintainRecordForm.confirmor = res.confirmor
        this.maintainRecordForm.verifyTime = res.verifyTime
        this.maintainRecordForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaintainRecordList(this.searchForm).then(res => {
        this.maintainRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaintainRecordList(this.searchForm).then(res => {
        this.maintainRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaintainRecordList(this.searchForm).then(res => {
        this.maintainRecordPage = res
      })
    },
    deviceChange (value) {
      if (value) {
        let device = this.deviceList.find(item => item.id === value)
        this.maintainRecordForm.deviceName = device.deviceName
        this.maintainRecordForm.deviceNo = device.deviceNo
        this.maintainRecordForm.spec = device.spec
      } else {
        this.maintainRecordForm.deviceName = ''
        this.maintainRecordForm.deviceNo = ''
        this.maintainRecordForm.spec = ''
      }
    }
  }
}
</script>

<style>
</style>
