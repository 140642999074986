import axios from '@/common/axios'
import qs from 'qs'

export function addMaintainRecord (data) {
  return axios({
    method: 'post',
    url: '/device/maintainRecord/add',
    data: qs.stringify(data)
  })
}

export function deleteMaintainRecord (id) {
  return axios({
    method: 'delete',
    url: '/device/maintainRecord/delete/' + id
  })
}

export function updateMaintainRecord (data) {
  return axios({
    method: 'put',
    url: '/device/maintainRecord/update',
    data: qs.stringify(data)
  })
}

export function selectMaintainRecordInfo (id) {
  return axios({
    method: 'get',
    url: '/device/maintainRecord/info/' + id
  })
}

export function selectMaintainRecordList (query) {
  return axios({
    method: 'get',
    url: '/device/maintainRecord/list',
    params: query
  })
}
